import React from 'react';
import { gsap } from 'gsap';

// Pieces
import { Heading, Section } from '@thepuzzlers/pieces';

// Local components
import { BorderedImage } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { Paragraph } from '@thepuzzlers/pieces/dist/thepuzzlers-pieces.cjs.prod';

export const Header = ({
  staticData: { image },
  data: { headline, image_caption }
}) => {
  const imageRef = React.useRef();
  const headingRef = React.useRef();

  React.useEffect(() => {
    // Fade in Headline on page load
    const timeline = gsap
      .timeline({ delay: 0.5 })
      .from(headingRef.current, {
        y: 50,
        autoAlpha: 0,
        duration: 0.6,
        ease: 'power2'
      })
      // Move image on page load
      .from(
        imageRef.current,
        {
          yPercent: 11,
          xPercent: 11,
          duration: 1,
          ease: 'power2'
        },
        '-=0.2'
      );

    const killAnimation = () => {
      timeline.pause(0).kill(true);
    };

    return killAnimation;
  }, []);

  return (
    <Section>
      <Heading
        ref={headingRef}
        as="h1"
        sx={{
          gridColumn: [
            '1/span 12',
            '1/span 11',
            '2/span 20',
            '1/span 13',
            '1/span 10',
            '2/span 10'
          ],
          fontFamily: 'primary.normal',
          lineHeight: 1.5,
          '& > span': {
            fontFamily: 'primary.bold'
          },
          fontSize: ['2.8rem', '3.8rem', '4rem', '2.8rem', '3.6rem', '4.2rem']
        }}
        dangerouslySetInnerHTML={{
          __html: convertApiHtmlText(headline.html)
        }}
      />

      <BorderedImage
        externalImageRef={imageRef}
        animateOnScroll={false}
        sx={{
          gridColumn: [
            '1/span 10',
            '1/span 9',
            '2/span 15',
            '16/span 8',
            '14/span 10',
            '14/span 9'
          ],
          marginTop: ['100px', '100px', '100px', '-50%', '-45'],
          gridRow: 2
        }}
        yOffset={11}
        xOffset={11}
        image={image.src}
        imageAlt={image.alt}
        imageSx={{ boxShadow: 'elevation-1000' }}
      />
      <Paragraph
        sx={{
          fontFamily: 'body.medium',
          gridColumn: [
            '1/13',
            '1/span 9',
            '2/span 15',
            '16/span 8',
            '14/span 10',
            '14/span 9'
          ],
          gridRow: [null, null, null, 3, 3, 3],
          fontSize: ['1.8rem', '2rem', '2rem', '1.6rem', '2rem', '2.2rem'],
          mt: ['4rem', '6rem', '6rem', '3.5rem', '5.6rem', '6.4rem']
        }}>
        {image_caption}
      </Paragraph>
    </Section>
  );
};
