import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// Local Components
import { Spacer } from 'components';

// Self Components

import { useContactPageData, Header, Form } from 'sections/kontakt';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';

// hooks
import { useKisContactPageData } from 'graphqlHooks';

const Leistungen = () => {
  const { seo, header } = useContactPageData();

  const { form: cmsForm, header: cmsHeader } = useKisContactPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Spacer
        height={['7.8rem', '7.9rem', '10.5rem', '4.9rem', '6.2rem', '4.8rem']}
      />
      <Header staticData={header} data={cmsHeader} />
      <Spacer
        height={['16rem', '24rem', '24rem', '16rem', '24rem', '23.6rem']}
      />
      <Form data={cmsForm} />
      <Spacer height={['16rem', '24rem', '24rem', '16rem', '24rem', '24rem']} />
    </>
  );
};

export default injectIntl(Leistungen);
